import React from 'react';
import NumberFormat from 'react-number-format';
import { Grid, Table, Container, Card } from 'semantic-ui-react';

const ShareBreakdown = props => {
  const { investmentData } = props;
  return (
    <Container text style={{ paddingBottom: '10px' }}>
      <Grid>
        <Grid.Column>
          <Card fluid>
            <Card.Content>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Valuation at Sale: </Table.Cell>
                    <Table.Cell textAlign="right">
                      <NumberFormat value={ investmentData.valuation.toFixed(2) } displayType="text" prefix="$" thousandSeparator={true} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Balance Remaining at Sale:</Table.Cell>
                    <Table.Cell textAlign="right">
                      <NumberFormat value={ investmentData.balanceAtSale.toFixed(2) } displayType="text" prefix="$" thousandSeparator={true} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Their Percent: </Table.Cell>
                    <Table.Cell textAlign="right">
                      <NumberFormat value={ (investmentData.theyGetRate * 100).toFixed(2) } displayType="text" suffix="%" />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>They Get: </Table.Cell>
                    <Table.Cell textAlign="right" style={{ color: 'red' }}>
                      <NumberFormat value={ investmentData.theyGet.toFixed(2) } displayType="text" prefix="$" thousandSeparator={true} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>I Get: </Table.Cell>
                    <Table.Cell textAlign="right" style={{ color: 'green' }}>
                      <NumberFormat value={ investmentData.iGet.toFixed(2) } displayType="text" prefix="$" thousandSeparator={true} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Effective Cost: </Table.Cell>
                    <Table.Cell textAlign="right" style={{ color: 'red' }}>
                      <NumberFormat value={ investmentData.effectiveCost.toFixed(2) } displayType="text" prefix="$" thousandSeparator={true} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default ShareBreakdown;