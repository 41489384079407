import React from 'react';
import { Container, Radio } from 'semantic-ui-react'
import CalculatorForm from 'features/calculator/components/CalculatorForm';
import ShareBreakdown from 'features/calculator/components/ShareBreakdown';
import AmortizationTable from 'features/calculator/components/AmortizationTable';

class CalculatorContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      amortizationData: [],
      showAmortization: false,
      investmentData: {
        theyGet: 0,
        theyGetRate: 0,
        buyoutAmount: 0,
        iGet: 0,
        valuation: 0,
        balanceAtSale: 0,
        effectiveCost: 0,
      },
      monthToSell: 36,
      data: {
        interest: 0,
        totalPaid: 0,
        monthlyRate: 0,
        amortization: [],
      }
    };
  }

  calculatePayment = (balance, interestRate, terms = 360) => {
    let monthlyRate = interestRate/12;
    return {
      monthlyRate,
      payment: balance * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -terms)))
    };
  }

  handleSubmit = async values => {
    let data = {
      interest: 0,
      totalPaid: 0,
      monthlyRate: 0,
      amortization: [],
    };

    let loanAmount = values['purchasePrice'] - values['downPaymentDollar'];
    let {monthlyRate, payment} = this.calculatePayment(loanAmount, values['interestRate']/100);
    data.monthlyRate = monthlyRate;
    
    for (let count = 0; count < 360; count++) {
      let interest = 0;
      let monthlyPrincipal = 0;

      interest = (loanAmount * data.monthlyRate).toFixed(2);
      monthlyPrincipal = (payment - interest).toFixed(2);
      data.interest += interest;
      data.totalPaid += payment;
      data.amortization.push({
        paymentNumber: (count + 1),
        interest,
        principal: monthlyPrincipal > loanAmount ? loanAmount.toFixed(2) : monthlyPrincipal,
        balance: monthlyPrincipal > loanAmount ? 0 : (loanAmount - monthlyPrincipal).toFixed(2),
      });
      loanAmount -= monthlyPrincipal;
    }

    let iData = {
      theyGet: 0,
      theyGetRate: 0,
      buyoutAmount: 0,
      iGet: 0,
      valuation: 0,
      balanceAtSale: 0,
      effectiveCost: 0,
    };

    iData.theyGetRate = (values.purchasePrice * (values.investmentPercent/100)/values.purchasePrice) * 3.3;
    iData.theyGet += values.purchasePrice * (values.investmentPercent/100);
    let appreciation = values.purchasePrice * (0.03 * values.numberYears);
    iData.theyGet += appreciation * iData.theyGetRate;
    iData.valuation = parseFloat(values.purchasePrice) + appreciation;
    iData.effectiveCost = iData.theyGet - (values.purchasePrice * (values.investmentPercent/100))
    iData.balanceAtSale = parseFloat(data.amortization[(values.numberYears * 12)-1].balance);
    iData.iGet += ((iData.valuation) - iData.balanceAtSale) - iData.theyGet;

    this.setState({amortizationData: data.amortization, investmentData: iData, monthToSell: values.numberYears * 12 });
  }

  render() {
    const {
      showAmortization,
      amortizationData,
      investmentData,
      monthToSell
    } = this.state;
    return (
      <Container>
        <CalculatorForm onSubmit={this.handleSubmit} />
        <ShareBreakdown investmentData={investmentData} />
        <Radio
          label='Show Amortization Schedule'
          toggle
          style={{ paddingBottom: '10px', paddingTop: '10px' }}
          onChange={() => {
            this.setState({showAmortization: !showAmortization});
          }}
        />
        {showAmortization && (<AmortizationTable amortizationData={amortizationData} monthToSell={monthToSell} />)}
      </Container>
    );
  }
}

export default CalculatorContainer;