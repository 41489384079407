import React from 'react';
import { Radio, Table, Container, Grid, Card } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

import Form from 'features/form/components/Form';
import { Values, Fields, Validation } from 'core/formConfig/calculator';

const CalculatorForm = props => {
  const { onSubmit:handleSubmit } = props;
  return (
    <Form
      values={Values}
      fields={Fields}
      validation={Validation}
      handleSubmit={handleSubmit}
    />
  );
}

export default CalculatorForm;
