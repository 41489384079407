import * as Yup from 'yup';

export const Values = {
  interestRate: 3.500,
  purchasePrice: 300000,
  downPaymentDollar: 60000,
  downPaymentPercent: 20,
  investmentPercent: 10,
  myPercentage: 10,
  zipCode: "92101",
  numberYears: 3,
};

export const Validation = Yup.object().shape({
  purchasePrice: Yup.number()
    .min(50000)
    .max(2500000)
});

export const Fields = [
  [
    {
      type: 'currency',
      name: 'purchasePrice',
      label: 'Purchase Price',
      placeholder: '300000',
      onChange: (e, values, changeHandler) => {
        changeHandler(e);
        changeHandler({
          target: {
            name: 'downPaymentDollar',
            value: Math.round((parseInt(e.target.value) * (values.downPaymentPercent/100)))
          }
        });
      },
      width: 8
    },
    {
      type: 'currency',
      name: 'downPaymentDollar',
      label: 'Down Payment ($)',
      placeholder: '60000',
      width: 8
    },
  ],
  [
    {
      type: 'percentage',
      name: 'interestRate',
      label: 'APR',
      percent: {
        min: 2.000,
        max: 12.000,
        step: 0.125,
      },
      width: 8
    },
    {
      type: 'percentage',
      name: 'downPaymentPercent',
      label: 'Down Payment (%)',
      placeholder: '20',
      props: {
        disabled: true
      },
      width: 8,
      percent: {
        min: 0.000,
        max: 75.000,
        step: 0.125,
      },
      onChange: (e, values, changeHandler) => {
        changeHandler({
          target: {
            name: 'downPaymentPercent',
            value: e,
          }
        });
        changeHandler({
          target: {
            name: 'downPaymentDollar',
            value: Math.round(parseInt((e/100) * values.purchasePrice))
          }
        });
      }
    },
  ],
  [
    {
      type: 'percentage',
      name: 'investmentPercent',
      label: 'Investment Percentage',
      percent: {
        min: 0.000,
        max: 17.5,
        step: 0.125
      },
      onChange: (e, values, changeHandler, touched) => {
        changeHandler({
          target: {
            name: 'investmentPercent',
            value: e,
          }
        });
        changeHandler({
          target: {
            name: 'downPaymentPercent',
            value: e + values.myPercentage,
          }
        })
      },
      width: 8,
    },
    {
      type: 'percentage',
      name: 'myPercentage',
      label: 'My Down Payment Percentage',
      percent: {
        min: 2.500,
        max: 50.000,
        step: 0.125
      },
      onChange: (e, values, changeHandler, touched) => {
        changeHandler({
          target: {
            name: 'myPercentage',
            value: e,
          }
        });
        changeHandler({
          target: {
            name: 'downPaymentPercent',
            value: e + values.investmentPercent,
          }
        });
      },
      width: 8
    }
  ],
  {
    type: 'percentage',
    name: 'numberYears',
    label: 'Sell/Buyout after # Years',
    percent: {
      min: 3,
      max: 30,
      step: 1,
    }
  }
];