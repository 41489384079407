import React from 'react';
import { Formik } from 'formik';

import DefaultTemplate from 'features/form/templates/Default';

export default props => {
  const {
    handleSubmit,
    render: UserComponent,
    values,
    fields,
    validation,
  } = props;

  const Template = UserComponent || DefaultTemplate;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validation}
      render={formikProps => <Template formFields={fields} {...formikProps} />}
    />
  );
}
