import React from 'react';
import { Container, Grid, Table } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

const AmortizationTable = props => {
  const { amortizationData, monthToSell } = props;
  return (
    <Container text>
      <Grid>
        <Grid.Column>
          <Table striped textAlign="center" color='red'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Month</Table.HeaderCell>
                <Table.HeaderCell>Payment</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Interest</Table.HeaderCell>
                <Table.HeaderCell>Remaining Balance</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {amortizationData.map(payment => {
              const positive = monthToSell === payment.paymentNumber;
              return (
                <Table.Row key={`payment-number-${payment.paymentNumber}`} positive={positive} >
                  <Table.Cell>{payment.paymentNumber}</Table.Cell>
                  <Table.Cell>
                    <NumberFormat
                      value={(parseFloat(payment.interest) + parseFloat(payment.principal)).toFixed(2)}
                      prefix="$"
                      displayType="text"
                      thousandSeparator={true}
                    />    
                  </Table.Cell>
                  <Table.Cell>
                    <NumberFormat value={payment.principal} prefix="$" displayType="text" thousandSeparator={true} />
                  </Table.Cell>
                  <Table.Cell>
                    <NumberFormat value={payment.interest} prefix="$" displayType="text" thousandSeparator={true} />
                  </Table.Cell>
                  <Table.Cell>
                    <NumberFormat value={payment.balance} prefix="$" displayType="text" thousandSeparator={true} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default AmortizationTable;