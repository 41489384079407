import React from 'react';
import { Header } from 'semantic-ui-react';

import logo from './logo.svg';
import './App.css';


import CalculatorContainer from 'features/calculator/containers/CalculatorContainer';

function App() {
  return (
    <div className="App">
      <Header as='h1'>Should I Co-Invest?</Header>
      <Header as='h3' sub>A calculator to help determine whether or not co-investment is a good idea.</Header>
      <br />
      <CalculatorContainer />
    </div>
  );
}

export default App;
