import React from 'react';
import {
  Button,
  Form,
  Grid,
  Segment,
  Container,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { Slider } from 'react-semantic-ui-range';
import { FieldArray } from 'formik';

import { textFields, fieldHasLabel } from 'features/form/config';

const RenderField = props => {
  const {
    field,
    touched,
    handleChange,
    handleBlur,
    errors,
    values,
  } = props;

  const selectProps = field.type === 'select'
    ? {
      label: field.label,
      placeholder: field.placeholder,
      options: field.options,
      name: field.name,
    }
    : null;

  return (
    <Form.Field
      width={field.width}
    >
      {
        field.label && fieldHasLabel.includes(field.type) &&
        (
          <label
            style={{
              textAlign: 'left',
              color: fieldHasLabel.includes(field.type) && errors[field.name] && touched[field.name] ? 'red' : null
            }}
          >
            {field.label}
          </label>
        )
      }
      {
        textFields.includes(field.type) && (
          <input
            type={field.type}
            onChange={e => {
              if (field.hasOwnProperty('onChange')) {
                field.onChange(e, values, handleChange, touched);
              } else {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            value={values[field.name]}
            name={field.name}
            placeholder={field.placeholder}
          />
        )
      }
      {
        field.type === 'currency' && (
          <NumberFormat
            value={values[field.name]}
            onBlur={handleBlur}
            onValueChange={e => {
              const { formattedValue, value } = e;
              let d = { target: {name: field.name, value }};

              if (field.hasOwnProperty('onChange')) {
                field.onChange(d, values, handleChange, touched);
              } else {
                handleChange(d);
              }
            }}
            name={field.name}
            prefix="$"
            thousandSeparator={true}
          />
        )
      }
      {
        field.type === 'percentage' && (
          <div>
            <Slider
              value={values[field.name]}
              color="red"
              {...field.props}
              settings={{
                min: field.percent.min,
                max: field.percent.max,
                step: field.percent.step,
                onChange: e => {
                  if (field.hasOwnProperty('onChange')) {
                    field.onChange(e, values, handleChange, touched);
                  } else {
                    handleChange({
                      target: {
                        name: field.name,
                        value: e
                      }
                    });
                  }
                }
              }}
            />
            <Input
                label={{ basic: true, content: '%'}}
                labelPosition="right"
                placeholder={field.placeholder}
                value={parseFloat(values[field.name]).toFixed(3)}
                name={field.name}
                onChange={handleChange}
                onBlur={handleBlur}
            />
          </div>
        )
      }
      {
        field.type === 'checkbox' && (
          <Form.Checkbox
            onChange={(e, data) => {
              e.target = data;
              handleChange(e);
            }}
            name={field.name}
            label={field.label}
          />
        )
      }
      {
        selectProps !== null && (
          <Dropdown
            width={field.width}
            selection
            {...selectProps}
            fluid
            value={values[field.name]}
            onChange={(e, data) => {
              const d = {target: data};
              handleChange(d);
            }}
          />
        )
      }
      {
        field.type === 'checkboxGroup' && (
          <FieldArray
            name={field.name}
            render={arrayHelpers => (
              <div
                style={{
                  justifyContent: 'space-between',
                  flex: 1,
                  flexDirection: 'column',
                  display: 'flex'
                }}
              >
                <div style={{ flexDirection: 'row', flex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  {field.options.map(option => (
                    <div
                      key={`phenotype_${option.id}`}
                      style={{ flexShrink: 1, flexGrow: 1, flexBasis: '25%' }}
                    >
                      <Form.Checkbox
                        name={option.name}
                        value={option.id}
                        label={option.name}
                        onChange={(e, data) => {
                          if (data.checked) arrayHelpers.push(option.id);
                          else {
                            const idx = values[field.name].indexOf(option.id);
                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          />
        )
      }
      {
        textFields.includes(field.type)
        && !field.label
        && touched[field.name]
        && errors[field.name]
        && (<p>{errors[field.name]}</p>)
      }
    </Form.Field>
  )
};

const FormFieldGroup = props => {
  const {
    group,
    ...rest
  } = props;

  return (
    <Form.Group>
      {
        group.map(field => (
          <RenderField
            key={`field_input_${field.name}`}
            field={field}
            {...rest}
          />
        ))
      }
    </Form.Group>
  )
};

export default props => {
  const {
    formFields,
    handleSubmit,
    ...rest
  } = props;

  return (
    <Container text>
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1
      }}
    >
      <Grid.Column>
        <Form onSubmit={handleSubmit}>
          <Segment>
            {formFields.map((field, i) => {
              if (Array.isArray(field)) {
                return <FormFieldGroup key={`field_group_${i}`} group={field} {...rest} />;
              }
              if (typeof field === "object") {
                return <RenderField key={`form_field_${i}`} field={field} {...rest} />;
              }
              return null
            })}
            <Button type="submit" primary fluid size="large">
              Submit
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
    </Container>
  );
};
